import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { WrapNameGift } from '@app/assets/WrapNameGift'
import { Outlink } from '@app/components/Outlink'
import { CustomTypography } from '@app/components/customs'
import { useNFTImage } from '@app/hooks/useAvatar'
import { useChainId } from '@app/hooks/useChainId'
import { getSupportLink } from '@app/utils/supportLinks'

const GiftWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: min(60vh, ${theme.space['52']});
  `,
)

const DescriptionWrapper = styled(CustomTypography)(
  ({ theme }) => css`
    display: inline;
    text-align: center;
    a {
      display: inline-block;
    }
    margin-bottom: ${theme.space['2']};
  `,
)

export const WrapName = ({ name }: { name: string }) => {
  const { t } = useTranslation('profile')
  const chainId = useChainId()
  const nftUrl = useNFTImage(name, chainId)

  return (
    <>
      <GiftWrapper>
        <WrapNameGift imageSrc={nftUrl.image || '/other/TemplateNFTImage.svg'} />
      </GiftWrapper>
      <DescriptionWrapper>
        <CustomTypography>
          {t('details.wrap.description')}{' '}
          <span>
            <Outlink href={getSupportLink('nameWrapper')}>
              {t('action.learnMore', { ns: 'common' })}
            </Outlink>
          </span>
        </CustomTypography>
      </DescriptionWrapper>
    </>
  )
}
