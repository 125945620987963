import customCoins from '@app/constants/customCoins.json'

export const coinsWithIcons: string[] = [
  'eth',
  'btc',
  'bnb',
  'xrp',
  'matic',
  'doge',
  'ltc',
  'dot',
  'sol',
  'abbc',
  'ada',
  'ae',
  'aion',
  'algo',
  'ar',
  'ardr',
  'ark',
  'atom',
  'avax',
  'bcd',
  'bch',
  'bcn',
  'btg',
  'bts',
  'celo',
  'ckb',
  'clo',
  'cro',
  'dash',
  'dcr',
  'dgb',
  'divi',
  'egld',
  'ela',
  'eos',
  'etc',
  'etn',
  'ewt',
  'fil',
  'flow',
  'ftm',
  'gno',
  'go',
  'grin',
  'grs',
  'hbar',
  'hive',
  'hnt',
  'icx',
  'iost',
  'iota',
  'iotx',
  'iris',
  'kava',
  'kmd',
  'ksm',
  'lsk',
  'luna',
  'nano',
  'nas',
  'near',
  'neo',
  'nrg',
  'nuls',
  'one',
  'ont',
  'poa',
  'ppc',
  'qtum',
  'rdd',
  'rune',
  'rvn',
  'srm',
  'steem',
  'strat',
  'stx',
  'sys',
  'tfuel',
  'theta',
  'tomo',
  'trx',
  'vet',
  'via',
  'vlx',
  'vsys',
  'wan',
  'waves',
  'xem',
  'xhv',
  'xlm',
  'xmr',
  'xtz',
  'xvg',
  'zec',
  'zen',
  'zil',
  ...customCoins,
]
